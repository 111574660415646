import styled from 'styled-components';

const NavWrapper = styled.nav`
  z-index: 800;

  &.dai_header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80px;
    transition: all 0.3s;
    background: white;
    border-bottom: 2px solid #000;

    &.sticky {
      position: fixed;
      top: 0;
      width: 100%;
      background: white;
      backdrop-filter: blur(15px);
      z-index: 998;
      margin-top: 0px;
      transition: all 0.2s;
    }
  }

  .dai_menu_sect {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dai_menu_left_sect {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 15%;

    .logo {
      width: 50%;
      height: 50%;
      a {
        display: inline-block;
      }
    }
  }

  .menu_right {
    width: 85%;
    display: flex;
    align-items: center;
    justify-content: end;
  }

  .dai_menu_list {
    margin-left: 65px;
    margin-right: 150px;
    max-width: 514px;
    min-width: 409px;
    width: 100%;

    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 0;
      padding: 0;

      li {
        position: relative;
        cursor: pointer;

        a {
          font-family: "Bakbak One";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          text-align: center;
          text-transform: uppercase;
          color: #000000;
        }

        &:hover {
          a {
            color: grey;
          }
        }

        /* Submenu */
        &.submenu {
          .sub_menu_sect {
            background: red;
            position: absolute;
            border-top: 50px solid transparent;
            top: calc(100% + 35px);
            width: 900%;
            visibility: hidden;
            opacity: 0;
            z-index: -100;
            transition: all 0.5s;
            transform: translateY(20px);
            display: flex;
            flex-wrap: wrap;

            .sub_menu_list {
              padding: 35px 20px;
              flex-wrap: wrap;
              list-style: none;
              display: flex;
              background: blue;
              li {
                width: 50%;
                padding: 5px 20px;
                a {
                  font-family: "Bakbak One";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 40px;
                  color: #000000;
                  text-transform: uppercase;
                }

                &:hover {
                  a {
                    color: grey;
                  }
                }
              }
            }
          }

          &:hover {
            .sub_menu_sect {
              transform: translateY(0px);
              visibility: visible;
              opacity: 1;
              z-index: 99;
            }
          }
        }
      }
    }
  }

  .dai-menu-btn {
    display: flex;
    align-items: center;
    justify-content: end;
    min-width: 200px;
    padding-left: 20%;

    button {
      color: black;
      text-transform: uppercase;
      font-family: "Bakbak One";
      font-weight: 400;
      font-size: 16px;
    }

    .menu_btn {
      display: none;
      border: none;
      background: transparent;
      cursor: pointer;

      svg {
        font-size: 40px;
      }
    }
  }

  @media (max-width: 1024px) {
    .dai_menu_list {
      display: none;
      visibility: hidden;
      opacity: 0;
    }

    .menu_right {
      .menu_btn {
        display: block;
      }
    }
  }

  @media (max-width: 991px) {
    .dai_menu_list {
      display: none;
      visibility: hidden;
      opacity: 0;
    }

    .menu_right {
      .menu_btn {
        display: block;
      }
    }
  }

  @media (max-width: 667px) {
    .dai_menu_btns {
      .connect_btn {
        display: none;
      }

      .menu_btn {
        svg {
          font-size: 30px;
        }
      }
    }
  }

  @media (max-width: 540px) {
    .dai_menu_left_sect {
      width: 180px;

      .logo {
        img {
          width: 100px;
        }
      }
    }

    .dai_menu_right_sect {
      width: 50%;

      .dai_menu_right_sect {
        width: 50%;
      }
    }
  }
`;

export default NavWrapper;
