import React from 'react';
import { BrandsContainer, BrandImage, BrandLink, BrandList, BrandTitle } from './Brands.style';
import logo1 from '../../../assets/images/logo/Jikiu.png';
import logo2 from '../../../assets/images/logo/Nagamochi.png';

const brands = [
  { id: 1, name: 'Jikiu', logo: logo1, link: 'https://www.jikiu.com' },
  { id: 2, name: 'Nagamochi', logo: logo2, link: 'https://www.sbparts.jp' },
  // tambahkan lebih banyak merek sesuai kebutuhan
];

const Brands = () => {
  return (
    <BrandsContainer id="brands">
      <BrandTitle>Brands</BrandTitle>
      <BrandList>
        {brands.map(brand => (
          <BrandLink key={brand.id} href={brand.link} target="_blank" rel="noopener noreferrer">
            <BrandImage src={brand.logo} alt={brand.name} />
          </BrandLink>
        ))}
      </BrandList>
    </BrandsContainer>
  );
};

export default Brands;
