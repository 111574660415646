import styled from "styled-components";

const MobileMenuWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;

  .mobile_menu_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .mobile_menu_content {
    position: relative;
    width: 80%;
    max-width: 300px;
    height: 100%;
    background: white;
    padding: 20px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .mobile_menu_close {
      border: none;
      background: transparent;
      cursor: pointer;
      font-size: 24px;
      align-self: flex-end;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;

      li {
        margin: 10px 0;

        a {
          text-decoration: none;
          color: black;
          font-size: 18px;
        }
      }
    }
  }
`;

export default MobileMenuWrapper;
