import React, { useState } from 'react';
import axios from 'axios';
import { 
  ContactUsContainer, 
  ContactForm, 
  FormGroup, 
  Input, 
  TextArea, 
  SubmitButton, 
  ContactInfo 
} from './ContactUs.style';

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('http://localhost:3002/api/contact', formData);
      alert('Message sent successfully!');
      setFormData({ name: '', email: '', message: '' });
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message.');
    }
  };

  return (
    <ContactUsContainer>
      <h1>Contact Us</h1>
      <ContactForm onSubmit={handleSubmit}>
        <FormGroup>
          <label>Name</label>
          <Input 
            type="text" 
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            required 
          />
        </FormGroup>
        <FormGroup>
          <label>Email</label>
          <Input 
            type="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />
        </FormGroup>
        <FormGroup>
          <label>Message</label>
          <TextArea 
            name="message" 
            value={formData.message} 
            onChange={handleChange} 
            required 
          />
        </FormGroup>
        <SubmitButton type="submit">Submit</SubmitButton>
      </ContactForm>
      <ContactInfo>
        <p>You can also reach us at contact@dai-motor.com</p>
        <p>Phone: +62-1234-5678</p>
        <p>Address: Jl. Example No. 123, Jakarta, Indonesia</p>
      </ContactInfo>
    </ContactUsContainer>
  );
};

export default ContactUs;
