import React, { useState, useEffect } from "react";
import { MdNotes } from "react-icons/md";
import MobileMenu from "./mobileMenu/MobileMenu";
import Logo from '../../../assets/images/logo/logo.png';
import NavWrapper from './Header.style';

const Header = () => {
  const [isMobileMenu, setMobileMenu] = useState(false);
  const [isSticky, setSticky] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenu(!isMobileMenu);
  };

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <NavWrapper className={`dai_header ${isSticky ? 'sticky' : ''}`} id="navbar">
        <div className="container">
          <div className="dai_menu_sect">
            <div className="dai_menu_left_sect">
              <div className="logo">
                <a href="/">
                  <img src={Logo} alt="dai Logo" />
                </a>
              </div>
            </div>
            <div className="menu_right">
              <div className="dai_menu_list">
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About</a>
                  </li>
                  <li>
                    <a href="/product">Product</a>
                  </li>
                  <li>
                    <a href="/catalogue">Catalogue</a>
                  </li>
                  <li>
                    <a href="/contact">Contact</a>
                  </li>
                </ul>
              </div>
              <div className="dai-menu-btn">
                <button className="menu_btn" onClick={handleMobileMenu}>
                  <MdNotes />
                </button>
              </div>
            </div>
          </div>
        </div>
      </NavWrapper>
      {isMobileMenu && <MobileMenu mobileMenuhandle={handleMobileMenu} />}
    </>
  );
};

export default Header;
