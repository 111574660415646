import styled from "styled-components";
import sectionBgImage from "../../../assets/images/banner/banner.jpg";


const BannerV1Wrapper = styled.section`
  background: url(${sectionBgImage});
  min-height: 450px;
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 192px;

  .daimotor_v1_baner_left {
    margin-top: 66px;
    max-width: 500px;
    width: 100%;
    min-height: 550px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px 33% 10%;
    
    h2 {
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 80px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 32px;
    }

    .daimotor-font {
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 80px;
      text-transform: uppercase;
      color: #51bef6;
      margin-bottom: 32px;
      text-shadow: -2px -2px 0 #fff, 2px -2px 0 #fff, -2px 2px 0 #fff, 2px 2px 0 #fff;
    }

    h3 {
      font-family: "Bakbak One";
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 60px;
      text-transform: uppercase;
      color: #ffffff;
    }

    .banner_buttons {
      display: flex;
      column-gap: 30px;
      margin-top: 58px;
    }

    .button_color {
      background-color : #51bef6;
    }
  }

  @media only screen and (max-width: 1199px) {
    .daimotor_v1_baner_left {
      img{
        width: 750px;
        display: flex;
        margin-right: 100px;
      }
      h2 {
        font-size: 50px;
        line-height: 65px;
      }

      h3 {
        font-size: 34px;
        line-height: 38px;
      }

      .baner_buttons {
        margin-top: 40px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    
    .daimotor_v1_baner_left {
      
      img{
        width: 750px;
        display: flex;
        margin-right: 150px;
      }

      margin-top: 0px;
      justify-content: flex-start;
    }

    .daimotor_v1_baner_right {
      margin: 70px auto;
      padding: 0px 68px;
      max-width: 568px;
      width: 100%;

      .daimotor_v1_baner_right_img {
        width: 55%;
      }
    }
  }

  @media only screen and (max-width: 767px) {


    .daimotor_v1_baner_left {
      img{
        width: 250px;
        display: flex;
        margin-right: 250px;
      }
      h2 {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 28px;
        line-height: 38px;
        
      }

      .banner_buttons{
        margin-top: 25px;
      }
    }

    .daimotor_v1_baner_right {
      padding-right: 0px;

      .daimotor_v1_baner_right_img_sect {
        height: 480px;
      }
    }
  }

  @media only screen and (max-width: 480px) {

    
    .daimotor_v1_baner_left {

      h2 {
        font-size: 37px;
      }

      h3 {
        font-size: 22px;
      }

      .banner_buttons{
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        
            .daimotor-btn + .daimotor-btn {
                margin-top: 20px;
            }
        }
    }

    .daimotor_v1_baner_right {
      padding-left: 34px;

    }
  }

  @media (max-width: 375px) {
    .daimotor_v1_baner_left {
      img{
        width: 200px;
        display: flex;
        margin-right: 200px;
      }
      h2 {
        font-size: 35px;
      }
      h3 {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 360px) {

    
    .daimotor_v1_baner_left {

    }
  }
`;

export default BannerV1Wrapper;
