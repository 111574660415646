
import styled from "styled-components";

const CTAStyleWrapper = styled.section`
  .dai-motor_title_section {
    width: 45%;
    margin: 0 auto;
    padding-top: 40px;
    img {
      display: none;
    }

    h2,
    h3 {
      margin: 0;
    }
  }
  .title-emo {
    text-align: center;
    font-size: 50px;
  }

  .dai-motor_v1_cta_content {
    position: relative;
    z-index: 1;
 
  }

  .cta_social_links {
    max-width: 740px;
    margin: auto;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      
      li {
        width: 61%;
        height: 60px;
        backdrop-filter: blur(10px);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 40%;
        a {
          color: #ffffff;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 80px;
          }
          img {
            width: 90px;
            height: 90px;
          }
        }
        .social_hover_shape {
          position: absolute;
          top: 7px;
          left: 7px;
          opacity: 0;
          visibility: hidden;
          transition: all 0.4s;
        }
        transition: background 0.3s ease;

        &:hover {
          transform: scale(1.1); 
        }

        &:hover {
          .social_hover_shape {
            opacity: 1;
            visibility: visible;
          }
        }
      }
      li + li {
        margin-left: 15px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .dai-motor_title_section {
      width: 60%;
    }
    .title-emo {
      font-size: 40px;
    }
  }
@media only screen and (max-width: 575px) {
  .cta_social_links {
    max-width: 225px;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;

      li {
        height: auto;
        width: 100%;
        margin-bottom: 5px; /* Optional: Add margin between items */
        a {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            height: 70px;
            width: 70px;
          }
          svg {
            font-size: 14px;
          }
        }
      }
    }
  }
}

  @media (max-width: 425px) {
    .dai-motor_title_section {
      width: 35%;
    }
    .title-emo {
      font-size: 30px;
    }


    .footer_social_links {
      ul {
        li {
          height: 40px;
          width: 12%;
          a {
            svg {
              font-size: 12px;
            }
            img {
              height: 12px;
            }
          }
        }
      }
    }
  }
`;

export default CTAStyleWrapper;
