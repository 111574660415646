import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import {
  FeaturedSection,
  ProductItem,
  ProductImage,
  ProductName,
  ProductPrice,
  BuyButton,
  CustomArrow,
} from './style';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css"; 

const FeaturedProducts = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:3002/products');
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, []);

  const handleBuyClick = (link) => {
    if (!link.startsWith('http://') && !link.startsWith('https://')) {
      link = `http://${link}`;
    }
    window.open(link, '_blank');
  };

  const NextArrow = (props) => {
    const { onClick } = props;
    return <CustomArrow onClick={onClick} style={{ right: '5px' }}>▶</CustomArrow>;
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return <CustomArrow onClick={onClick} style={{ left: '5px' }}>◀</CustomArrow>;
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  return (
    <FeaturedSection id="featured-products">
      <h2>DAi motor Featured Products</h2>
      <Slider {...settings}>
        {products.map(product => (
          <ProductItem key={product.id}>
            <ProductImage src={`http://localhost:3002${product.image}`} alt={product.name} />
            <ProductName>{product.name}</ProductName>
            <ProductPrice>Rp {product.price}</ProductPrice>
            <BuyButton onClick={() => handleBuyClick(product.productLink)}>
              Buy Now
            </BuyButton>
          </ProductItem>
        ))}
      </Slider>
    </FeaturedSection>
  );
};

export default FeaturedProducts;
