import TokopediaIcon from "../images/logo/tokopedia.png"
import ShopeeIcon from "../images/logo/shopee.png";
import LazadaIcon from "../images/logo/lazada.png";
import Bukalapak from "../images/logo/bukalapak.png";
import Blibli from "../images/logo/blibli.png";



const data = [
  {
    thumb: TokopediaIcon,
    url: "https://tokopedia.link/dai-motor ",
    
  },
  {
    thumb: ShopeeIcon,
    url: "https://shopee.co.id/dai_motor",
  },
  {
    thumb: LazadaIcon,
    url: "https://s.lazada.co.id/s.IHDz0",
  },
  {
    thumb: Bukalapak,
    url: "https://www.bukalapak.com/u/daimw",
  },
  {
    thumb: Blibli,
    url: "https://blibli.onelink.me/GNtk/ypxlv6ny",
  },
];

export default data;
