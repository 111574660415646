import React from 'react';
import { AboutContainer, AboutContent, AboutTitle, AboutText } from './About.style';

const About = () => {
  return (
    <AboutContainer id="about">
      <AboutContent>
        <AboutTitle>About Us</AboutTitle>
        <AboutText>
          Welcome to our website! We are dedicated to providing the best automotive parts for your needs. 
          Our mission is to deliver high-quality products and exceptional service to our customers.
        </AboutText>
      </AboutContent>
    </AboutContainer>
  );
};

export default About;
