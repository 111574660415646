import BannerV1Wrapper from "./Banner.style";
import Logo2 from "../../../assets/images/logo/logo2.png";

const Banner = () => {
 
  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          
            <div className="daimotor_v1_baner_left">
             
                <img src={Logo2} alt="Dai Motor" />
              
            </div>
          
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
