import styled from 'styled-components';

export const ContactUsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em;
  background-color: #f9f9f9;
  color: #333;
  min-height: 80vh;
  text-align: center;

  h1 {
    Color: Black;
    margin-top: 2em;
    margin-bottom: 1em;
  }
`;

export const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  padding: 2em;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    padding: 1.5em;
  }

  @media (max-width: 480px) {
    padding: 1em;
  }
`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;

  label {
    margin-bottom: 0.5em;
    font-weight: bold;
  }
`;

export const Input = styled.input`
  padding: 0.75em;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  width: 100%;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  @media (max-width: 480px) {
    padding: 0.5em;
  }
`;

export const TextArea = styled.textarea`
  padding: 0.75em;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
  width: 100%;
  height: 150px;

  &:focus {
    border-color: #007bff;
    outline: none;
  }

  @media (max-width: 480px) {
    padding: 0.5em;
  }
`;

export const SubmitButton = styled.button`
  padding: 0.75em;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 480px) {
    padding: 0.5em;
  }
`;

export const ContactInfo = styled.div`
  margin-top: 2em;
  font-size: 1.2em;

  p {
    color: Black;
    margin: 0.5em 0;
  }

  @media (max-width: 480px) {
    font-size: 1em;
  }
`;
