import SectionTitle from "../../../common/sectionTitle";
import data from "../../../assets/data/socialProfile";
import CTAStyleWrapper from './Style';



const CTA = () => {
  return (
    <CTAStyleWrapper className="cta_wrapper_v1">
      <div className="container">
        <SectionTitle
          className="dai-motor_title_section text-center"
          title="Shop now"
        />
        
        <div className="dai-motor_v1_cta_content">
          <div className="cta_social_links">
            <ul>
              {data?.map((item, i) => (
                <li key={i}>
                  <a href={item.url}>
                    {item.thumb ? (
                      <img src={item.thumb} alt="dai-motor nft profiles" />
                    ) : (
                      item.icon
                    )}
                  </a>
                </li>
              ))}
            </ul>
          </div>

        </div>
      </div>
    </CTAStyleWrapper>
  );
};

export default CTA;