import { useState } from "react";
import CTA from "../../section/cta";


import backToTopIcon from "../../../assets/images/icon/back_to_top.svg";
import instagramIcon from "../../../assets/images/icon/instagram.png";
import TwitterIcon from "../../../assets/images/icon/x.png";
import FacebookIcon from "../../../assets/images/icon/facebook.png";
import YoutubeIcon from "../../../assets/images/icon/youtube.png";
import TiktokIcon from "../../../assets/images/icon/tiktok.png";

import FooterStyleWrapper from "./Footer.style";

const Footer = () => {
  const [copyrightYear] = useState(new Date());

  return (
    <FooterStyleWrapper>
      <CTA />

      <div className="dai-motor_v1_main_footer">
        <div className="dai-motor_v1_main_footer_overlay"></div>

        <div className="footer_bottom">
          <div className="footer_bottom_content">

            <div className="container">
              <div className="footer_menu">
                <div className="bottom_footer_left">

                  <div className="copiright_text">
                    <p>Copyright &copy; {copyrightYear.getFullYear()} Dai-motor All Right Reserved</p>
                  </div>
                </div>
                
                <a href="# " className="bact_to_top_btn">
                  <img src={backToTopIcon} alt="dai motor back to top" />
                </a>
                <div className="bottom_footer_right">
                <a
                    href="https://instagram.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={instagramIcon} alt="dai motor instagram" />
                  </a>
                  <a
                    href="https://twitter.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={TwitterIcon} alt="dai motor X" />
                  </a>
                  <a
                    href="https://facebook.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={FacebookIcon} alt="dai motor facebook" />
                  </a>
                  <a
                    href="https://youtube.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={YoutubeIcon} alt="dai motor youtube" />
                  </a>
                  <a
                    href="https://tiktok.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img src={TiktokIcon} alt="dai motor Tiktok" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FooterStyleWrapper>
  );
};

export default Footer;
