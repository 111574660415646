import styled from 'styled-components';

export const AboutContainer = styled.div`
  padding: 3em 1em;
  background-size: cover;
  color: white;
  text-align: center;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  @media (max-width: 768px) {
    padding: 2em 1em;
  }
`;

export const AboutContent = styled.div`
  max-width: 80%;
  margin: 0 auto;
  padding: 2em;
  border-radius: 10px;
  @media (max-width: 768px) {
    padding: 1.5em;
  }
`;

export const AboutTitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 1.5em;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;

export const AboutText = styled.p`
  font-size: 1.2em;
  line-height: 1.5;
  margin: 0;
  color: #000000;

  @media (max-width: 768px) {
    font-size: 1em;
  }
`;
