// src/components/ProductPage/ProductPage.jsx

import React from 'react';
import {
  ProductPageContainer,
  ProductCard,
  ProductImage,
  ProductDetails,
  ProductName,
} from './ProductPage.style';

const products = [
  { id: 1, name: 'Brake Pads & Shoes', image: 'path/to/image1.jpg' },
  { id: 2, name: 'Rotor Disc', image: 'path/to/image2.jpg' },
  { id: 3, name: 'Brake and Clutch Cylinders', image: 'path/to/image3.jpg' },
  { id: 4, name: 'Brake and Clutch MdHouseSiding', image: 'path/to/image4.jpg' },
  { id: 5, name: 'Brake Calipers Kit', image: 'path/to/image5.jpg' },
];

const ProductPage = () => {
  return (
    <ProductPageContainer>
      {products.map(product => (
        <ProductCard key={product.id}>
          <ProductImage src={product.image} alt={product.name} />
          <ProductDetails>
            <ProductName>{product.name}</ProductName>
          </ProductDetails>
        </ProductCard>
      ))}
    </ProductPageContainer>
  );
};

export default ProductPage;
