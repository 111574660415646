import React from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from "./pages/home";
import Product from "./pages/product";
import ContactUs from "./pages/contactus";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home/>} />
                <Route path="/product" element={<Product/>} />
                <Route path="/contact" element={<ContactUs/>} />
            </Routes>
        </Router>
    )
}

export default App;