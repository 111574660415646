// src/components/ProductPage/ProductPage.style.js

import styled from 'styled-components';

export const ProductPageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 2em;
  background-color: #f5f5f5;
  margin-top: 80px;
  min-height: 80vh;

  @media (max-width: 768px) {
    padding: 30px;
    gap: 15px;
  }

  @media (max-width: 480px) {
    padding: 20px;
    gap: 10px;
  }
`;

export const ProductCard = styled.div`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 300px;
  transition: transform 0.2s;

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 768px) {
    width: 350px;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

export const ProductImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 180px;
  }

  @media (max-width: 480px) {
    height: 150px;
  }
`;

export const ProductDetails = styled.div`
  padding: 15px;
  text-align: center;

  @media (max-width: 768px) {
    padding: 10px;
  }

  @media (max-width: 480px) {
    padding: 8px;
  }
`;

export const ProductName = styled.h3`
  font-size: 1.2em;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1em;
  }

  @media (max-width: 480px) {
    font-size: 0.9em;
  }
`;
