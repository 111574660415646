import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/Header";
import Footer from "../components/section/footer/v2/Footer";
import Contact from "../components/section/contactUs/ContactUs";

const Home = () => {
  // eslint-disable-next-line no-unused-vars


  return (
    <>
      <Layout>
        <GlobalStyles />
        <Header />
        <Contact />
        <Footer />
      </Layout>
    </>
  );
};

export default Home;
