import GlobalStyles from "../assets/styles/GlobalStyles";
import Layout from "../common/layout";
import Header from "../components/section/header/Header";
import Banner from "../components/section/banner/Banner";
import FeaturedProducts from "../components/section/featuredProducts/FeaturedProducts";
import Brands from "../components/section/brands/Brands";
import About from "../components/section/about/About";
import Footer from "../components/section/footer/Footer";

const Home = () => {
  // eslint-disable-next-line no-unused-vars


  return (
    <>
      <Layout>
        <GlobalStyles />
        <Header />
        <Banner />
        <FeaturedProducts />
        <Brands />
        <About />
        <Footer />
      </Layout>
    </>
  );
};

export default Home;
