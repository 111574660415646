import styled from 'styled-components';

export const FeaturedSection = styled.section`
  padding: 3em 1em;
  text-align: center;
  font-family: "Bakbak One";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  position: relative;

  h2 {
    margin-bottom: 60px;
  
  }
`;

export const ProductItem = styled.div`
  background: #fff;
  margin: 0 auto;
  border-radius: 8px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
  transition: transform 0.3s ease;
  padding: 1em;
  gap: 20px;
  max-width: 95%;
  flex-shrink: 0; /* Prevent shrinking when fewer products are shown */

  &:hover {
    transform: translateY(-5px);
  }

  @media (max-width: 1024px) {
    max-width: 80%;
  }

  @media (max-width: 600px) {
    max-width: 90%;
  }
`;

export const ProductImage = styled.img`
  width: 100%;
  height: auto;
  border: 1px solid grey; /* Tambahkan border untuk debugging */
  background-color: grey; /* Tambahkan background sementara */
   
  &:hover {
    background-color: darkgreen;
    border: 1px solid blue;
  }
`;

export const ProductName = styled.h3`
  font-size: 1.2rem;
  margin: 10px 0;
`;

export const ProductPrice = styled.p`
  font-size: 1rem;
  color: #888;
`;

export const BuyButton = styled.button`
  background-color: green;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  &:hover {
    background-color: darkgreen;
  }
`;

export const CustomArrow = styled.div`
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  border: none;
  padding: 20px;
  cursor: pointer;
  z-index: 1;
  font-size: 2rem;
  border-radius: 5px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.8);
    transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 1.5);
    transform-origin: left center;
  }
`;
