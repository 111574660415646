import styled from 'styled-components';
import sectionBgImage from "../../../assets/images/banner/banner2.jpg";

export const BrandsContainer = styled.div`
  padding: 3em 1em;
  background: url(${sectionBgImage});
  min-height: 450px;
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  text-align: center;
  text-transform: uppercase;
  color: white;
`;

export const BrandList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 150px;
  margin: 150px 0 90px;

  @media (max-width: 768px) {
    gap: 90px;
  }

  @media (max-width: 480px) {
    gap: 70px;
    margin: 100px 0 20px;
  }
`;

export const BrandLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 5px;

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
  }
`;

export const BrandImage = styled.img`
  max-width: 150%;
  max-height: 150%;
  object-fit: contain;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;

export const BrandTitle = styled.h2`
  font-size: 2.5em;
  margin-bottom: 0.5em;

  @media (max-width: 768px) {
    font-size: 2em;
  }
`;
