import React from "react";
import { MdClose } from "react-icons/md";
import MobileMenuWrapper from "./MobileMenu.style";


const MobileMenu = ({ mobileMenuhandle }) => {
  return (
    <MobileMenuWrapper>
      <div className="mobile_menu_overlay" onClick={mobileMenuhandle}></div>
      <div className="mobile_menu_content">
        <button className="mobile_menu_close" onClick={mobileMenuhandle}>
          <MdClose />
        </button>
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/about">About</a></li>
          <li><a href="/product">Product</a></li>
          <li><a href="/catalogue">Catalogue</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </div>
    </MobileMenuWrapper>
  );
};

export default MobileMenu;
